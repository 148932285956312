import { getBusinessUnit } from "@shared/Utils/analytics-utils";
import { pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import { BusinessUnit } from "@shared/Utils";

export type GA4SearchClickEvent = {
  event: "search_click";
  search_term: string;
  search_has_predictions: boolean;
  search_predictions: string; // "no predictions" if no predictions
  business_unit: BusinessUnit;
};

export const sendGA4SearchClickEvent = (term: string, predictions: Array<string>) => {
  const event: GA4SearchClickEvent = {
    event: "search_click",
    search_term: term,
    search_has_predictions: predictions.length > 0,
    search_predictions: predictions.length > 0 ? predictions.join(", ") : "no predictions",
    business_unit: getBusinessUnit(),
  };
  pushToDataLayer(event);
};
