import { GA4FrontPageEvent } from "../content/frontpage-analytics";
import { BaseGA4FeedbackEvent } from "../content/feedback-analytics";
import { GA4ContactFormEvent, GA4LeadFormEvent, GA4NewsLetterEvent } from "../content/form-analytics";
import { BaseGA4MenuEvent } from "../content/menu-analytics";

import { GA4FAQEvent } from "../content/faq-analytics";
import { BaseGA4FunnelTracking } from "../content/funnel-analytics";
import { BaseGA4LoginData } from "../content/login-analytics";
import { GA4PhoneClickEvent } from "../content/phone-analytics";
import { BaseMBBEvent, BaseGA4MBBEvent } from "../ecommerce/mbb/b2c/mbb-b2c-analytics.models";
import { BaseEcommerceAddonEvent } from "../ecommerce/voice/voice-addon-analytics";
import { GA4B2BSubscriptionEvent } from "../ecommerce/mbb/b2b/mbb-b2b-analytics";
import { GA4SubscriptionCardEvent, GA4SubscriptionToggleEvent } from "../content/subscription-analytics";
import { GA4SearchClickEvent } from "../content/search-analytics";

export const clearPreviousEcommerceObjectToDataLayer = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
};

type BaseDataLayerEvents =
  | BaseMBBEvent
  | BaseGA4MBBEvent
  | BaseGA4MenuEvent
  | BaseEcommerceAddonEvent
  | BaseGA4FeedbackEvent
  | GA4FrontPageEvent
  | GA4PhoneClickEvent
  | GA4FAQEvent
  | GA4NewsLetterEvent
  | GA4LeadFormEvent
  | GA4ContactFormEvent
  | GA4SubscriptionCardEvent
  | GA4SubscriptionToggleEvent
  | GA4B2BSubscriptionEvent
  | GA4SearchClickEvent
  | BaseGA4FunnelTracking
  | BaseGA4LoginData;

export const pushToDataLayer = (event: BaseDataLayerEvents): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};
