import { isEmptyString } from "@shared/Utils";
import { bootstrapSearchEvents } from "../SearchEvents/search-events";

type MenuLoaderModel = {
  viewString: string;
};

const createScriptTag = (url: string) => {
  const script = document.createElement("script");
  script.setAttribute("src", url);
  return script;
};

const createLinkTag = (url: string) => {
  const linkElem = document.createElement("link");
  linkElem.setAttribute("rel", "stylesheet");
  linkElem.setAttribute("href", url);
  return linkElem;
};

class IceMenuLoader extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: "open" });
  }

  get baseUrl() {
    const url = this.getAttribute("baseurl");
    if (isEmptyString(url)) {
      throw "Base url is missing in ice-menuloader!";
    }
    return url;
  }

  get loadFooter() {
    return this.getAttribute("show-footer") === "true";
  }

  get loadFooterBottomOnly() {
    return this.getAttribute("bottom-only");
  }

  get getMark() {
    return this.getAttribute("mark");
  }

  sendStandaloneEvent = () => {
    const eventToBeDispatched = new CustomEvent("__ice_InitStandalone", {
      detail: { document: this.shadowRoot },
    });

    document.dispatchEvent(eventToBeDispatched);
    bootstrapSearchEvents(this.shadowRoot);

    document.removeEventListener("StandaloneScriptsLoaded", this.sendStandaloneEvent);
  };

  connectedCallback() {
    this.shadowRoot.appendChild(createScriptTag(`${this.baseUrl}/js/dist/standalone.min.js`));
    this.shadowRoot.appendChild(createLinkTag(`${this.baseUrl}/css/brand/standalone.min.css`));

    const url = encodeURI(
      this.loadFooter
        ? `${this.baseUrl}/api/footer?bottomonly=${this.loadFooterBottomOnly}&mark=${this.getMark}&home=${this.baseUrl}`
        : `${this.baseUrl}/api/header?mark=${this.getMark}&home=${this.baseUrl}`,
    );

    fetch(url, {
      headers: {
        "Content-type": "application/json",
      },
      mode: "cors",
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data: MenuLoaderModel) => {
        const div = document.createElement("div");
        div.innerHTML = data.viewString;
        this.shadowRoot.appendChild(div);

        setTimeout(() => {
          this.sendStandaloneEvent();
        }, 50);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

customElements.define("ice-menuloader", IceMenuLoader);
