import { BusinessUnit } from "./common-utils";

/*
 * Andy's Log, Stardate 30.05.2024
 * This is my final entry.
 * So Long, and Thanks for All the Fish <3
 * Now my watch has ended.
 * Cherrio!
 */

export const getBusinessUnit = (): BusinessUnit => (window.location.pathname.startsWith("/bedrift") ? "B2B" : "B2C");
export const isMobileBroadbandPath = (): boolean =>
  window.location.pathname.startsWith("/mobilt-bredband") ||
  window.location.pathname.startsWith("/bedrift/mobilt-bredband");
export const isMobilabonnementPath = (): boolean => window.location.pathname.startsWith("/mobilabonnement");
export const isPathInUrl = (path: string): boolean => window.location.pathname.toLowerCase().includes(path);

export const attachClickEventListener = (
  targets: NodeListOf<Element> | Element,
  handler: EventListenerOrEventListenerObject,
): void => {
  if (targets instanceof Element) {
    targets.addEventListener("click", handler);
  } else {
    targets.forEach((element) => {
      element.addEventListener("click", handler);
    });
  }
};

export const handleAnalyticsError = (
  error: string,
  context: string,
  errorType: "reference" | "error" = "reference",
): void => {
  switch (errorType) {
    case "reference":
      throw new ReferenceError(`${error} in ${context}`);
    case "error":
      throw new Error(`${error} in ${context}`);
    default:
      console.warn(`${error} in ${context}`);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addIndexToItems = (items: any[]) => {
  return items.map((item, index) => ({
    ...item,
    index: index,
  }));
};
